
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



















































.performance-metrics-ranking {
  display: flex;
  flex-direction: column;
  margin-left: $--clb-space-3;

  .metric-header {
    width: 100%;

    h5 {
      margin: 0 0 $--clb-space-6 0;
    }
  }

  .metric-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ranking-item {
      width: 100%;
      margin-bottom: 12px;

      p {
        line-height: 12px;
        font-size: $--clb-font-size-xs;
      }

      .ranking-bar-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;

        .el-progress {
          margin: 0;
          width: calc(100% - 42px);
          height: 16px;
        }

        .el-progress-bar__outer,
        .el-progress-bar__inner {
          border-radius: 0;
        }

        small {
          font-size: 10px;
          line-height: 12px;
          width: 42px;
          text-align: right;
          font-weight: 300;
        }
      }

      &:hover {
        p {
          color: #262626;
        }

        .el-progress-bar__inner {
          background-color: $--clb-color-primary__dark;
        }
      }
    }
  }
}


          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        












































































































































































































.jb-mobile-device {
  .performance-metrics-overview-list-item {
    &__chart {
      path {
        transition: none;
      }
    }
  }
}


          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



































































































.svg-bar-cont {
  display: inline-block;
  position: relative;
  width: 100%;

  .bar-chart {
    outline: transparent;
    user-select: none;
    height: auto;

    .axis {
      text {
        fill: $--clb-body-font;
        font-size: 11px;
        -webkit-font-smoothing: antialiased;
      }

      .domain {
        stroke: transparent;
      }

      .tick {
        line {
          stroke: $--clb-disabled-color;
        }
      }

      .axis-x {
        text {
          font-size: 11px;
          text-anchor: middle;
          transform: translate(-5px, 6px) rotate(0deg);
          -webkit-font-smoothing: antialiased;
        }
      }
    }

    &__bar {
      transition: all 0.4s ease-out, opacity 0.2s, stroke 0.3s ease, fill 0.3s ease;
      outline: transparent;
    }

    .interactive .bar-chart__wrapper:hover .bar-chart__bar:not(:hover) {
      fill: $--clb-bg-accent-color;
      stroke: $--clb-disabled-color;
    }

    .interactive .bar-chart__wrapper:hover .bar-chart__bar:hover {
      fill: $--clb-color-primary__dark;
    }
  }
}

@media (max-width: 450px) {
  .svg-bar-cont {
    .bar-chart {
      .axis {
        text,
        .axis-x text {
          font-size: 16px;
        }
      }
    }
  }
}


          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        







































































































.performance-metrics-visualization {
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: normal;

  @apply tw-bg-white;

  .performance-metrics-overview-list {
    grid-area: list;
  }

  .performance-metrics-main-chart {
    grid-area: chart;
    display: flex;
    align-items: flex-end;

    > svg {
      display: flex;
      height: auto;
    }
  }

  .performance-metrics-ranking {
    grid-area: ranking;
    display: none;
  }
}

@media (min-width: $--sm) {
  .performance-metrics-visualization {
    display: grid;
    grid-gap: 10px;
    grid-template: 'list chart' 310px/1fr minmax(300px, 552px);

    .no-data {
      top: 100px;
    }
  }
}

@media (min-width: 860px) {
  .performance-metrics-visualization {
    grid-template: 'list chart ranking' 310px/1fr minmax(300px, 552px) 1fr;

    .performance-metrics-ranking {
      display: block;
    }
  }
}

/** Uncomment when adv sidebar is live */
// @media (min-width: $--md) {
//   .performance-metrics-visualization {
//     grid-template: 'list chart' 310px/1fr minmax(300px, 552px);

//     .performance-metrics-ranking {
//       display: none;
//     }
//   }
// }

// @media (min-width: 1250px) {
//   .performance-metrics-visualization {
//     grid-template: 'list chart ranking' 310px/1fr minmax(300px, 552px) 1fr;

//     .performance-metrics-ranking {
//       display: block;
//     }
//   }
// }

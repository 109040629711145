
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




































































































































































.jb-mobile-device {
  .performance-metrics-overview-list {
    &__item-wrapper {
      &::before {
        transition: none !important;
      }
    }
  }
}

.campaign-reporting {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $--clb-layout-2 0 $--clb-layout-1 0;
    max-height: 33px;
    min-height: 33px;

    &-right {
      line-height: $--clb-layout-2;
      display: flex;
      padding-top: $--clb-space-1;
      justify-content: flex-start;
      align-items: center;
      min-width: 117px;

      &-title {
        font-size: 12px;
      }
    }
  }
}


          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



















































































































.export-button {
  line-height: 16px;

  .el-button-group {
    display: flex;
  }

  .el-button:hover {
    background: #f3f5fd;
  }

  .el-button:active,
  .el-button.active,
  .el-button:focus,
  .el-button:hover {
    outline: none;
    color: #5072ff;
    transform: translateY(0);
  }

  &[disabled='disabled'] .el-button {
    color: #bababa !important;
    background: $--clb-disabled-color !important;
    border-color: $--clb-disabled-color !important;
    outline: transparent !important;
    font-weight: normal !important;
    box-shadow: none;

    &::before {
      background-color: transparent;
    }
  }
}
